import React, { useState, useRef } from "react";
import { Row } from "react-bootstrap";
import jobs from "../utils/jobs";
import CareerItem from "./CareerItem";

function Careers() {
  const myRef = useRef(null);
  const [showingJobs, setShowingJobs] = useState(jobs.slice(0, 3));
  const [showingAll, setshowingAll] = useState(false);
  const handleShowAll = () => {
    if (showingAll) {
      setShowingJobs(jobs.slice(0, 3));
    } else {
      setShowingJobs(jobs);
    }
    setshowingAll(!showingAll);
  };
  const executeScroll = () =>
    myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  return (
    <main>
      <div class="banner">
        <div class="banner-inner">
          <div class="text-center">
            <h3 class="b-text">Find the Job That Fits Your Life</h3>
            <p class="p-text">We offer positions for <span className="purple"> 9 </span> roles at the moment</p>
            <button className="button-primary" onClick={executeScroll}>
              Find jobs
            </button>
          </div>
        </div>
      </div>
      <div ref={myRef}></div>
      <div class="container py-5 my-5">
        <div class="row">
          <div class="col-lg-12">
            <div class="main-title-2">
              <h1>Recent Jobs</h1>
            </div>
          </div>
        </div>
        <Row>
          {showingJobs.map((item, index) => (
            <CareerItem job={item} key={index} />
          ))}
        </Row>
        <div className="d-flex justify-content-center mt-5 pt-3 align-items-center">
          <button
            class=" btn bg-transparent rounded"
            onClick={handleShowAll}
            style={{ color: "#4abddc", border: "1px solid #4abddc" }}
          >
            {showingAll ? "Show Less Jobs" : "Browse All Jobs"}
          </button>
        </div>
      </div>
    </main>
  );
}

export default Careers;
