export default [
  {
    id: 1,
    title: "Frontend Developer",
    location: "Remote",
    nature: "Contract/Fulltime",
    minSalary: 25,
    maxSalary: 35,
    minExp: 3,
    maxExp: 5,
    notes: `Frontend Development. UI/UX work. An artistic eye for style.`,
  },
  {
    id: 2,
    title: "Backend Developer",
    location: "Remote",
    nature: "Contract/Fulltime",
    minSalary: 25,
    maxSalary: 35,
    minExp: 3,
    maxExp: 5,
    notes: `The wizard that makes things work. Caster of databases, API's, intergrations, algorithms and more.`,
  },
  {
    id: 3,
    title: "Blockchain Developer",
    location: "Remote",
    nature: "Contract/Fulltime",
    minSalary: 25,
    maxSalary: 35,
    minExp: 3,
    maxExp: 5,
    notes: `The Craftsman of crypto! From NFT's to Tokenization, this is a dev who knows how to intergrate with the latest blockchains and systems.`,
  },
  {
    id: 1,
    title: "Marketing Content Creator",
    location: "Remote",
    nature: "Contract/Fulltime",
    minSalary: 10,
    maxSalary: 17,
    minExp: 1,
    maxExp: 2,
    notes: `languages only
    differ in their grammar`,
  },
  {
    id: 2,
    title: "Sales Representative",
    location: "Remote",
    nature: "Contract/Fulltime",
    minSalary: 10,
    maxSalary: 100,
    minExp: 0,
    maxExp: 20,
    notes: `Can you sell me this pen? What about ice to an eskimo?`,
  },
  {
    id: 3,
    title: "Application Development Liasion",
    location: "Remote",
    nature: "Contract/Fulltime",
    minSalary: 10,
    maxSalary: 15,
    minExp: 0,
    maxExp: 2,
    notes: `The translator between the clients and the tech teams. The more teams you talk to the more you'll learn!`,
  },
  {
    id: 1,
    title: "Project Manager",
    location: "Remote",
    nature: "Contract/Fulltime",
    minSalary: 15,
    maxSalary: 35,
    minExp: 2,
    maxExp: 4,
    notes: `The person keeping the team on track and the clients happy.`,
  },
  {
    id: 2,
    title: "Wireframe Specialist",
    location: "Remote",
    nature: "Contract/Fulltime",
    minSalary: 25,
    maxSalary: 35,
    minExp: 1,
    maxExp: 2,
    notes: `Figma, Adobe XD, PenPot. The one who manifests ideas into an interactable visual.`,
  },
  {
    id: 3,
    title: "Fullstack Developer",
    location: "Remote",
    nature: "Contract/Fulltime",
    minSalary: 35,
    maxSalary: 45,
    minExp: 5,
    maxExp: 10,
    notes: `The aged old wizard who make an app exist from nothing but whispers from a conference call.`,
  },
];
