import xplane from "../Assets/projects/xplane.png";
import gamasonic from "../Assets/projects/gamasonic.png";
import golunapark from "../Assets/projects/golunapark.png";
import gowago from "../Assets/projects/gowago.png";
import tridot from "../Assets/projects/tridot.png";
import christembassysanantonio from "../Assets/projects/christembassysanantonio.png";
import go4rent from "../Assets/projects/go4rent.png";
import thejessicalee from "../Assets/projects/thejessicalee.png";
import fruitbonbon from "../Assets/projects/fruitbonbon.png";
import topgolfentertainmentgroup from "../Assets/projects/topgolfentertainmentgroup.png";
import retox_music from "../Assets/projects/retox-music.png";
import stellarcircus from "../Assets/projects/stellarcircus.png";
import waycaytion from "../Assets/projects/waycaytion.png";
import turn14 from "../Assets/projects/turn14.png";
import travelbydenise from "../Assets/projects/travelbydenise.png";
import amacorp from "../Assets/projects/amacorp.png";
import napolitanohomes from "../Assets/projects/napolitanohomes.png";
import sostozoo from "../Assets/projects/sostozoo.png";
import pathfinder from "../Assets/projects/pathfinder.jpg";
import raze from "../Assets/projects/raze.png";
import TranscoiniSafeADA from "../Assets/projects/TranscoiniSafeADA.png";
import Kratom from "../Assets/projects/Kratom.jpg";
import Accruent from "../Assets/projects/accruent.png";
import Corodata from "../Assets/projects/corodata.png";
import Accolade from "../Assets/projects/accolade.png";
import Znakomstva from "../Assets/projects/znakomstva.png";
import Wetherspoon from "../Assets/projects/wetherspoon.png";
import Spotlist from "../Assets/projects/spotlist.png";
import Ruqya from "../Assets/projects/ruqya.png";
import PriceRite from "../Assets/projects/pricerite.png";
import HuntPost from "../Assets/projects/huntpost.png";
import ArtPro from "../Assets/projects/artpro.png";

export const projectsData = [
  {
    id: 1,
    title: "gamasonic",
    imgPath: gamasonic,
    link: "https://gamasonic.com",
    description: `Gama Sonic Solar Lighting-Upscale Solar Lamps & Solar Lamp Posts We Are The World Leader In Solar Lighting With Thousands of Solar Powered Lights Installed Worldwide. Browse Our Selection of Outdoor Solar Lights, Solar Lamp Posts, Solar Accent Lights, And More!
	---
	Gamasonic site maintenance, enhancements & custom integrations were implemented to handle support requests, new pages, and bug fixes. We also updated Gamasonic's backend to the latest version of PHP then optimized their WPengine site to reach lightning-fast speeds.`,
    category: ["fintech"],
    library: ["react"],
    language: ["javascript"],
  },
  {
    id: 2,
    title: "golunapark",
    link: "http://www.golunapark.com",
    imgPath: golunapark,
    description: `The all-in-one solution for trivia hosts. Create unique, live, interactive shows that family and friends can enjoy together.
	---
	Kaizen was tasked with creating a fun and interactive virtual venue that offers more than just the boring broadcast experience. We leveraged the agora.io platform, react and node.js to create a modern platform offering event hosting, user-created breakout rooms, volume balancing, and private "sidebars". The app was hosted on AWS using Elastic Beanstalk and EC2.`,
    category: ["fintech"],
    library: ["react"],
    language: ["javascript"],
  },
  {
    id: 3,
    title: "gowago",
    link: "https://gowago.ch/en/",
    imgPath: gowago,
    description: `Go Wago - The New All-In-One Way to Lease a Car!
	Lease any car, with everything included on gowago.ch - 6 to 60 months, one monthly price, no surprise costs. New, used, electric, hybrid, petrol, 4x4, SUVs, and more!\
	---
	We worked on the backend which was based on data-driven, machine learning algorithms that enhanced the platform enables customers to do trade-offs between countless ​possible ​car ​configurations ​in ​real-time.`,
    category: ["fintech"],
    library: ["react"],
    language: ["javascript"],
  },
  {
    id: 4,
    title: "tridot",
    link: "https://tridot.com",
    imgPath: tridot,
    description: `We implemented several screens as pixel-perfect using react and Bootstrap based on given designs such as edit profile screen, onboarding flow screens, etc.`,
    category: ["ecommerce"],
    library: ["react"],
    language: ["python"],
  },
  {
    id: 5,
    title: "christembassysanantonio",
    link: "https://christembassysanantonio.org",
    imgPath: christembassysanantonio,
    description: `• A hybrid mobile app build with the WAMP stack with Angular JS, and Ionic then reinforced with load-balancing. 
	• Added using a Cordova Plugin the camera scan functionality to the application, to scan guests into the property
	• Worked on the website and admin backend to control and update the front-end functionality using Laravel 5+
	• Configured and worked in WAMP environment
	• Worked on different other modules like blogs, search directory, policies, forms, and protocols which provides various abilities to the co-workers to manage and search
	• Worked on custom content type creation, Views building, custom module development including Features, and importing of old site users and content
	• Used Angular.js framework for building web apps and is highly efficient with the Laravel PHP framework as well as using Restful services
	• Developed and utilized repositories within Laravel
	• Used JAVASCRIPT framework i.e. Angular JS extensively in the entire application
	• Created user backend access at different levels
	• Designed forms and user interfaces for administrator
	• Created the User Management for different clients
	• Created User Interfaces for different users
	• Created the initial design to determine the look and feel of the website
	• Expert in PHP session variables and cookies implementation
	• Expert in PHP connectivity backend files or database
	• Used CSS, HTML, and JavaScript for developing rich user interface
	• Implemented AJAX in front-end
	Environment: Ionic, Angular 2+(Angular 4, 5, 6, 7), Ionic, Laravel 5+, CodeIgniter, JavaScript, JQuery, MySQL, PHP, WordPress`,
    category: ["ecommerce"],
    library: ["react"],
    language: ["javascript"],
  },
  {
    id: 7,
    title: "go4rent",
    link: "https://go4rent.com/",
    imgPath: go4rent,
    description: `Go4rent Lease and rent a Home`,
    category: ["fintech"],
    library: ["react"],
    language: ["ruby"],
  },
  {
    id: 8,
    title: "thejessicalee",
    link: "https://www.thejessicalee.com/",
    imgPath: thejessicalee,
    description: `Jessica Lee is a Los Angeles-based actress, model, host, inspiring influencer, motivational speaker and active volunteer. Her recent work includes being featured in a global ad campaign for United Airlines, model #16 on Deal or No Deal hosted by Howie Mandel and guest appearances on General Hospital.`,
    category: ["fintech"],
    library: ["django"],
    language: ["javascript"],
  },
  {
    id: 9,
    title: "fruitbonbon",
    link: "http://www.fruitbonbon.com/",
    imgPath: fruitbonbon,
    description: ``,
    category: ["fintech"],
    library: ["angular"],
    language: ["javascript"],
  },

  {
    id: 10,
    title: "topgolfentertainmentgroup",
    link: "https://topgolfentertainmentgroup.com/",
    imgPath: topgolfentertainmentgroup,
    description: `
	Topgolf Entertainment Group is a global sports and entertainment community that connects people in meaningful ways through the experiences we create, the innovation we champion and the good that we do.`,
    category: ["fintech"],
    library: ["react"],
    language: ["javascript"],
  },
  {
    id: 11,
    title: "retox-music",
    link: "https://www.retox-music.com/",
    imgPath: retox_music,
    description: `Retox | music supervision agency founded in London in 2003 | offers creative searches, bespoke music, song licensing | TV, film, commercials, trailers, games`,
    category: ["fintech"],
    library: ["react"],
    language: ["javascript"],
  },
  {
    id: 12,
    title: "stellarcircus",
    link: "https://www.stellarcircus.com/",
    imgPath: stellarcircus,
    description: `	Stellar Circus is the ideal creative production company to create your own bespoke audiovisual content. We are creatives and producers making high quality content for various platforms across the digital scene.`,
    category: ["ecommerce"],
    library: ["react"],
    language: ["javascript"],
  },
  {
    id: 13,
    title: "waycaytion",
    link: "http://waycaytion.com/",
    imgPath: waycaytion,
    description: "	Waycaytion - Amazing Travel Deals",
    category: ["fintech"],
    library: ["spring boot"],
    language: ["java"],
  },
  {
    id: 14,
    title: "turn14",
    link: "https://www.turn14.com/",
    imgPath: turn14,
    description: `
	Turn 14 Distribution is a Performance Warehouse Distributor with distribution facilities strategically located in Hatfield, PA, Arlington, TX, and Reno, NV. Turn 14 Distribution's strategy consists of catering to niche vehicle markets, along with stocking its partner manufacturers' full product lines for quick order fulfillment.`,
    category: ["fintech"],
    library: ["angular"],
    language: ["javascript"],
  },
  {
    id: 15,
    title: "travelbydenise",
    link: "https://www.travelbydenise.net/",
    imgPath: travelbydenise,
    description: `We are a travel booking agency, it is our business to provides travel and tourism-related services. We provide fun local and international tour packages, leisure activities, airlines bookings, cruise lines, hotels, car rentals, and travel insurance.`,
    category: ["ecommerce"],
    library: ["laravel"],
    language: ["php"],
  },
  {
    id: 16,
    title: "amacorp",
    link: "https://amacorp.biz/",
    imgPath: amacorp,
    description: `	Providing Design and Project Management Consultancy tailor-made to fit the client’s design requirements.`,
    category: ["fintech"],
    library: ["django"],
    language: ["python"],
  },
  {
    id: 17,
    title: "napolitanohomes",
    link: "http://www.napolitanohomes.com",
    imgPath: napolitanohomes,
    description: `Napolitano Homes is a leader in the new home building industry who is passionately committed to creating innovative homes and communities.
	---
	As the main design agency for Napolitano Homes, we wanted to create a design for the company that reflected a modern and sleek feel that represented the company's home building style. We created the front-end UI, all graphics, and color schemes to reflect the company's profile as well as the photography seen on the website.`,
    category: ["ecommerce"],
    library: ["react"],
    language: ["javascript"],
  },

  {
    id: 18,
    title: "sostozoo",
    link: "https://www.sostozoo.hu/",
    imgPath: sostozoo,
    description: `Állatkert Nyíregyházán, Sóstó Zoo - Nyíregyházi Állatpark Fedezd fel világunkat! 5.000 állat, 500 faj, 300.000 négyzetméter. Magyarország leglátogatottabb vidéki turisztikai látványossága.
	---
	As one of our international clients, we worked to portray the love for animals in a way that transcends all language barriers.`,
    category: ["fintech"],
    library: ["ruby on rails"],
    language: ["ruby"],
  },
  {
    id: 19,
    title: "Raze",
    desc: "ERC20",
    link: "https://www.raze.network/",
    imgPath: raze,
    category: ["fintech"],
    library: ["ruby on rails"],
    language: ["ruby"],
  },

  {
    id: 20,
    title: "TranscoiniSafeADA",
    description: "ERC20",
    link: "https://www.transcoin.me/",
    imgPath: TranscoiniSafeADA,
    category: ["fintech"],
    library: ["ruby on rails"],
    language: ["ruby"],
  },

  // {
  //   id: 21,
  //   title: "iSafeADA",
  //   description: "BSC",
  //   link: "app.isafeada.com",
  //   imgPath: christembassysanantonio,
  //   category: ["fintech"],
  //   library: ["ruby on rails"],
  //   language: ["ruby"],
  // },

  // {
  //   id: 22,
  //   title: "Dotzilla",
  //   description: "BSC",
  //   link: "dotzilla.monster",
  //   imgPath: christembassysanantonio,
  //   category: ["fintech"],
  //   library: ["ruby on rails"],
  //   language: ["ruby"],
  // },
  // {
  //   title: "HackCoin",
  //   description: "IPFS/Filecoin",
  //   link: "www.cybersimulator.org",
  //   imgPath: christembassysanantonio,
  //   category: ["fintech"],
  //   library: ["ruby on rails"],
  //   language: ["ruby"],
  // },

  {
    id: 23,
    title: "Kratom",
    description: "WAVE",
    link: "https://docs.google.com/document/d/11nDExVaKPVfHPQxVT2F5c7WrR-fchSXbiWcEfZk5bss/edit?usp=sharing",
    imgPath: Kratom,
    category: ["fintech"],
    library: ["ruby on rails"],
    language: ["ruby"],
  },

  {
    id: 24,
    title: "Pathfinder",
    description: "WAVE",
    link: "https://docs.google.com/document/d/1ftxG9y37svJvvwDWAOkcOVs1FRvrQxsyfrZI3lWSjrw/edit?usp=sharing",
    imgPath: pathfinder,
    category: ["fintech"],
    library: ["ruby on rails"],
    language: ["ruby"],
  },

  {
    id: 25,
    title: "Accruent",
    description: "Accruent is committed to optimizing workplace and asset management, helping you unify your built environment to reach your greatest potential and fulfill your organization’s mission.",
    link: "https://www.accruent.com",
    imgPath: Accruent,
    category: ["marketplace"],
    library: ["react"],
    language: ["javascript"],
  },

  {
    id: 26,
    title: "Corodata",
    description: "Corodata is the largest independent information management company in California, providing full information management services with an emphasis on client satisfaction. We focus on security, compliance, and peace of mind for while handling information through all stages. Throughout this process, we keep our clients at the center of company, not shareholders.",
    link: "https://corodata.com/",
    imgPath: Corodata,
    category: ["marketplace"],
    library: ["react"],
    language: ["php"],
  },

  {
    id: 27,
    title: "Accolade",
    description: " Accolade provides care delivery, navigation and advocacy services to help every person live their healthiest life. We do this using a Personalized Healthcare approach that is personal, data driven and value based.",
    link: "https://www.accolade.com/",
    imgPath: Accolade,
    category: ["marketplace"],
    library: ["react"],
    language: ["javascript"],
  },


  {
    id: 28,
    title: "Znakomstva",
    description: `
    Dating with men or women for free. More than 30 millions of profiles are looking for someone to create romantic connections, find new friends or build strong relationships!
    ---
Znakomstva.ru - is a popular and free dating app for iOS devices.
---
Why Znakomstva.ru is so popular?
- you can really meet someone
- more than 32 000 000 verified profiles of men and women
- thousands people from your city would like to meet someone right now
- search with free filters for every taste
- use geolocation to find love nearby
- no hidden payments, you can control your subscription at any time
- chat for free and create profile in 2 clicks
    `,
    link: "https://apps.apple.com/us/app/znakomstva-ru-dating-app/id820830594",
    imgPath: Znakomstva,
    category: ["mobile-apps"],
    library: ["react-native"],
    language: ["dart"],
  },



  {
    id: 29,
    title: "Wetherspoon",
    description: `
    Order food and drinks with the Wetherspoon app, from your phone, and have them delivered to your table.
    ---
• Order a meal, a round of drinks or both – and we will bring them to your table.
• Paying is simple: use your card, PayPal or Apple Pay.
• Take advantage of the reorder function to purchase rounds, when in a group.
• Book a stay at one of our hotels.
    `,
    link: "https://apps.apple.com/us/app/wetherspoon/id1003707932",
    imgPath: Wetherspoon,
    category: ["mobile-apps"],
    library: ["react-native"],
    language: ["kotlin"],
  },


  {
    id: 30,
    title: "Spotlist",
    description: `
SpotList saves you time by combining information from Google Places, Facebook Posts, Foursquare Venues, Sqoot Deals and Yelp Businesses to help you find and give you a closer look at spots around you as well as all over the world very quickly.
    ---
1. Quickly find Spots around you by tapping on quick buttons or anywhere in the world using the autocomplete search functionality.
2. Check out deals in the search area that are relevant to your search. You can directly link to the deals from this screen. Currently only available in United States.
2. See Posts from relevant Facebook Pages in the search area to get more insight into happy hours , live music, specials, events etc.
3. Quickly find important information and take action on route, uber availability and cost estimate, menu, reservations etc right from the Spot screen.
4. Look at ratings & highlights of reviews and tips from Foursquare, Google and Yelp all in one screen. Tap on the ratings icons to go to the respective review pages to get more details. Not all spots have reviews everywhere but between the 3 of them, there is a very good chance you will find something.
5. Look at deals in and around the Spot. If the spot has a deal(s), that would show up first, followed by deals from other spots around there. You can directly link to the deals from this screen. Currently only available in United States. 
    `,
    link: "https://apps.apple.com/us/app/spotlist-find-and-take-a-closer-look-at-your-favorite-spots/id1121069376",
    imgPath: Spotlist,
    category: ["mobile-apps"],
    library: ["react-native"],
    language: ["swift"],
  },


  {
    id: 31,
    title: "Ruqya",
    description: `
    For Spiritual Healing against Evil Eye, Sihr, Black Magic, defense against evil magicians, Sahir's, jinns and shayateens.
    ---
With selected verses from Quran as prescribed in Authentic Sunnah of the Prophet (PBUS).
    `,
    link: "https://apps.apple.com/us/app/ruqya-%D8%A7%D9%84%D8%B1%D9%82%D9%8A%D8%A9-%D8%A7%D9%84%D8%B4%D8%B1%D8%B9%D9%8A%D8%A9/id652855869",
    imgPath: Ruqya,
    category: ["mobile-apps"],
    library: ["react-native"],
    language: ["dart"],
  },



  {
    id: 32,
    title: "PriceRite",
    description: `
        Plan your shopping trip easier than ever with the Price Rite Marketplace app! All the tools you need for savings, recipes, coupons and more in the palm of your hand.
        ---
Simple Navigation
Simple and easy to use interface. Easily navigate and find products you love faster.
---
Weekly Circular
View circular pages in the app. Add items to your cart or lists, while browsing circular items by category.
---
Digital Coupons
Browse and clip digital coupons to load directly to your account.
---
Grocery Delivery
Order grocery delivery powered by Instacart.
---
Manage Lists
Create, rename, remove and set active shopping lists. Add any item to lists from the app.  
    `,
    link: "https://apps.apple.com/au/app/price-rite-marketplace/id1533320393",
    imgPath: PriceRite,
    category: ["mobile-apps"],
    library: ["react-native"],
    language: ["kotlin"],
 },

 {
    id: 33,
    title: "HuntPost",
    description: `
    Download the latest HuntPost Marketplace app to shop on-the-go from thousands of products and services, that are sold by unique companies from around the world.
    ---
HuntPost Social Marketplace is a global online social network and e-commerce platform for the hunting, fishing and camping industry worldwide. We're home to a universe of special, extraordinary people and companies, all wildlife enthusiast who make spending time in the outdoors a central part of their lives.
---
Download the HuntPost Marketplace app to shop on-the-go from thousands of products and services, that are sold by unique companies from around the world.
    `,
    link: "https://apps.apple.com/us/app/huntpost-marketplace/id1527449649",
    imgPath: HuntPost,
    category: ["mobile-apps"],
    library: ["react-native"],
    language: ["swift"],
  },

  {

    id: 34,
    title: "ArtPro",
    description: `
    ArtPro focuses on collecting and collating the information of the global art collection and trading market. It provides mature art collectors and professional art brokers with more than 20 million pieces of auction data and analysis services from tens of thousands of auctions. It is committed to building a perfect art price index system and market trend index, making art trading easier. With massive auction data, high-definition auction pictures, real-time live broadcast of the world's top auctions, AR live picture hanging, virtual exhibition hall and practical Auction tools, you can enjoy your favorite works of art anytime, anywhere.
    `,
    link: "https://apps.apple.com/us/app/artpro-art-auction-results/id1486061934",
    imgPath: ArtPro,
    category: ["mobile-apps"],
    library: ["react-native"],
    language: ["dart"],
  },
	
  {

    id: 35,
    title: "X-Plane",
    description: `
    X-Plane is a fully imersive flight simulator with to-scale planes based on real models that currently are or were in use. Become immersed in the depth of flight and all of the intricacies that are required to make a safe voyage from one location to your destination while seeing the amazin sights on the way. Know that you will be able to see and recognize the parts of the plane as if you were sitting in one yourself.
    `,
    link: "https://www.x-plane.com/",
    imgPath: xplane,
    category: ["mobile-apps"],
    library: ["react-native"],
    language: ["dart"],
  },

];
