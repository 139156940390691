import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/new_logo.svg";

import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              <span className="purple"> A BIT ABOUT US </span> 
            </h1>
            <p className="home-about-body">
              Kaizen Apps is based out of the silicon valley area and is the premier one stop shop for application development of De-Fi, Smart Contract, IPFS, AI, Websites, Databases, and more!
              <br />
              <br />We are fluent in classics such as
              <i>
                <b className="purple"> C++, Javascript and Python. </b>
              </i>
              as well as the modern day crypto languages such as solidity and plutus.
              <br />
              <br />
              We're interested in world changing applications that span from&nbsp;
              <i>
                <b className="purple">Web 3.0 and blockchain technologies </b> to
                also in areas related to{" "}
                <b className="purple">
                artificial intelligence deep learning natural language processing algorithms.
                </b>
              </i>
              <br />
              <br />
              We're passionate in designing and developing products with frameworks including
              <b className="purple"> Node.js</b> and
              <i>
                <b className="purple">
                  {" "}
                  Modern Javascript Library and Frameworks
                </b>
              </i>
              &nbsp; like
              <i>
                <b className="purple"> React.js and Next.js</b>
              </i>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1> TESTIMONIALS </h1>
            <ul className="home-about-social-links">
              <li className="social-icons">
            <iframe title="testimonial1" width="100%" height="220" 
            src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&feed=%2Fkaizenapps%2Fshedistrict-testimonial%2F" 
            frameborder="0" >
              </iframe> 
              </li>
              {/* <li className="social-icons">
                <a href="https://twitter.com/KaizenApps" target="_blank"
                  rel="noopener noreferrer" className="icon-colour  home-social-icons"
                >
                </a>
              </li> */}
  
            </ul>
          </Col>

          <Col md={12} className="home-about-social">
            <h1>FIND US ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with us
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/kaizenapps"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/KaizenApps"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/company/kaizen-applications"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/kaizenapps/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>

    
    </Container>
  );
}
export default Home2;
