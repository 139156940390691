import React from "react";
import Card from "react-bootstrap/Card";
function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            <span className="purple">Kaizen Apps </span>
            is an applications development agency that originated in San Diego,
            California and moved to the{" "}
            <span className="purple">Silicon Valley</span>, the software
            technology capital of the world 🌎
            <br />
            We have developers of all types of specialties within our family.
            <br />
          </p>
          <p style={{ marginBlockEnd: 0, color: "#4abddc" }}>
            "Kaizen is the belief that small changes over time can create HUGE
            LIFE CHANGES."{" "}
          </p>
          <footer className="blockquote-footer">Taiichi Ohno</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
