import React, { useState } from "react";

function ReadMoreLess({ text, maxLength }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const renderedText =
    text?.length < maxLength
      ? text
      : isOpen
      ? text
      : `${text?.slice(0, maxLength)}...`;

  return (
    <>
      <p>
        {renderedText}{" "}
        {text?.length > maxLength && (
          <button onClick={toggle} className="read-more-less">
            {isOpen ? "read less" : "read more"}
          </button>
        )}
      </p>
    </>
  );
}

export default ReadMoreLess;
