import React from "react";
function Polkadot() {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      role="img"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 1871.3 2503"
    >
      <title>polkadot</title>
      <path
        className="st0"
        d="M948.2,0C425.4,2.1,2.1,425.4,0,948.2c0,104.7,16.9,208.7,50,308c24.7,67.6,98,104,166.8,83.1  c66.5-25.5,101.8-98.2,80.6-166.2c-28.1-77.4-40.6-159.5-36.9-241.7c11.4-377.6,326.7-674.5,704.3-663.1s674.5,326.7,663.1,704.3  c-10.7,353.5-289.1,640.6-642.2,662.1c0,0-133.1,8.1-199.3,16.2c-24.4,3.5-48.6,8.3-72.5,14.4c-3.4,3.5-8.9,3.5-12.4,0.1  c0,0-0.1-0.1-0.1-0.1c-2.4-3.1-2.4-7.5,0-10.6l20.6-112.4L847,980.1c15-70.2-29.7-139.3-99.9-154.3c-70.2-15-139.3,29.7-154.3,99.9  c0,0-297.3,1376.1-297.3,1388.6c-17,66.9,23.4,134.9,90.3,151.9c0.7,0.2,1.5,0.4,2.2,0.5h6.9c66.8,17.3,135-22.9,152.2-89.7  c0.3-1.1,0.6-2.2,0.8-3.4c-0.2-2.1-0.2-4.2,0-6.2c3.7-16.2,41.2-199.3,41.2-199.3c28.4-138.2,139.8-244.1,279.2-265.5  c28.7-4.4,149.3-12.5,149.3-12.5c520.8-51.9,900.9-516.2,848.9-1037C1819.1,378.1,1425.6,12.4,948.2,0z"
      />
      <path
        className="st1"
        fill={"#E6007A"}
        d="M1005.7,2186.3c-85.5-17.8-169.1,37.1-186.9,122.5c-0.2,0.8-0.3,1.6-0.5,2.4c-18.5,84.9,35.3,168.8,120.3,187.3  c0.1,0,0.2,0,0.3,0.1h4.4c83.2,20.1,166.9-31.1,186.9-114.2c0.2-0.6,0.3-1.3,0.5-1.9v-8.7C1145.4,2288,1090.6,2205.7,1005.7,2186.3z  "
      />
    </svg>
  );
}

export default Polkadot;
