import React, { useState, useRef } from "react";
import { Button, Container, Form } from "react-bootstrap";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
  AiOutlinePhone,
  AiOutlineMail,
} from "react-icons/ai";
import { FaLinkedinIn, FaWhatsapp } from "react-icons/fa";
import { SiMinutemailer } from "react-icons/si";
import * as emailjs from "emailjs-com";

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [business, setBusiness] = useState("");
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState("");
  const form = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name && email && business && message && phone) {
      emailjs
        .sendForm(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ID,
          form.current,
          process.env.REACT_APP_USER_ID
        )
        .then(
          function (response) {
            console.log("SUCCESS!", response.status, response.text);
            alert(response.text);
          },
          function (err) {
            console.log("FAILED...", err);
            alert("FAILED...", err);
          }
        );
    }
  };
  return (
    <Container fluid>
      {/* <Particle /> */}
      <Container>
        <div className="d-lg-flex form  b-light rounded">
          <div className="col-lg-7 mb-sm-5 mb-lg-0 p-5 background rounded">
            <header>
              <h4 className="text-white h2 mb-2">Let's Get in Touch</h4>
              <p className="text-white-50">
                We'll respond by the next business day!
              </p>
            </header>
            <Form ref={form} className="row" onSubmit={handleSubmit}>
              <Form.Group className="mb-2 col-12" controlId="name">
                <Form.Label className="text-light mb-1">Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Name..."
                  className="field-bg b-light"
                  required
                  defaultValue={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-2 col-12" controlId="business">
                <Form.Label className="text-light mb-1">Business</Form.Label>
                <Form.Control
                  type="text"
                  name="business"
                  placeholder="Business..."
                  className="field-bg b-light"
                  required
                  defaultValue={business}
                  onChange={(e) => setBusiness(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-2 col-md-6" controlId="email">
                <Form.Label className="text-light mb-1">Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Email..."
                  className="field-bg b-light"
                  required
                  defaultValue={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-2 col-md-6" controlId="phone">
                <Form.Label className="text-light mb-1">
                  Phone Number
                </Form.Label>
                <Form.Control
                  type="tel"
                  name="phone"
                  placeholder="Phone..."
                  className="field-bg b-light"
                  required
                  defaultValue={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-2 col-12" controlId="message">
                <Form.Label className="text-light mb-1">Message</Form.Label>
                <Form.Control
                  className="field-bg b-light"
                  as="textarea"
                  rows={3}
                  name="message"
                  placeholder="Enter your message..."
                  required
                  defaultValue={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </Form.Group>
              <div className="col-12 mt-3">
                <Button
                  className="col-12 submit font-weight-bold"
                  type="submit"
                >
                  Submit
                </Button>
              </div>
            </Form>
          </div>
          <div className="text-white col-lg-5 px-4 py-5 rounded">
            <header className="mb-4">
              <h4>Want to build out your idea?</h4>
              <p className="text-white-50">
                For a faster response our contact info is below:
              </p>
            </header>
            <div>
              <div className="d-flex mb-3 pl-0 bb">
                <div className="col-auto pl-0">
                  <span className="mb-2 contact-icon phone">
                    <AiOutlinePhone />
                  </span>
                </div>
                <div>
                  <p className="mb-1 font-weight-bold">Phone</p>
                  <p className="text-white-50">+1 (650) 319-7350</p>
                </div>
              </div>
              <div className="d-flex mb-3 pl-0 bb">
                <div className="col-auto pl-0">
                  <span className="contact-icon mb-2">
                    <SiMinutemailer />
                  </span>
                </div>
                <div>
                  <p className="mb-1 font-weight-bold">Telegram</p>
                  <p className="text-white-50">@kaizenapps</p>
                </div>
              </div>
              <div className="d-flex mb-3 pl-0 bb">
                <div className="col-auto pl-0">
                  <span className="contact-icon mb-2">
                    <AiOutlineMail />
                  </span>
                </div>
                <div>
                  <p className="mb-1 font-weight-bold">Email</p>
                  <p className="text-white-50">business@kaizenapps.com</p>
                </div>
              </div>
              <div className="d-flex mb-3 pl-0 bb">
                <div className="col-auto pl-0">
                  <span className="contact-icon mb-2">
                    <FaWhatsapp />
                  </span>
                </div>
                <div>
                  <p className="mb-1 font-weight-bold">Whatsapp</p>
                  <p className="text-white-50">+1 (858) 309-2028</p>
                </div>
              </div>
              <div className="d-flex mb-3 pl-0 bb">
                <div className="col-auto pl-0">
                  <span className="mb-2 contact-icon phone">
                    <AiOutlinePhone />
                  </span>
                </div>
                <div>
                  <p className="mb-1 font-weight-bold">Calendly Meeting</p>
                  <p className=""><a target="_blank" rel="noopener noreferrer" href="https://KaizenApps.com/meet">https://KaizenApps.com/meet</a></p>
                </div>
              </div>
            </div>
            <p className="font-weight-bold text-uppercase">Socials</p>
            <ul className="home-about-social-links social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/kaizenapps"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/KaizenApps"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/company/kaizen-applications"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/kaizenapps/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Container>
    </Container>
  );
}

export default Contact;
