import React, { useState } from "react";
import { Container, Row, Col, DropdownButton, Dropdown } from "react-bootstrap";

import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import { projectsData } from "../../utils/projectsData";

function Projects() {
  const [filteredProjects, setFilteredProjects] = useState(projectsData);
  const handleFilterByCategory = (arg) => {
    const items = projectsData.filter((item, index) =>
      item.category.includes(arg)
    );
    setFilteredProjects(items);
  };
  const handleFilterByLanguage = (arg) => {
    const items = projectsData.filter((item, index) =>
      item.language.includes(arg)
    );
    setFilteredProjects(items);
  };
  const handleFilterByLibrary = (arg) => {
    const items = projectsData.filter((item, index) =>
      item.library.includes(arg)
    );
    setFilteredProjects(items);
  };
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <div className="d-md-flex justify-content-between align-items-start">
          <div>
            <h1 className="project-heading">
              Kaizen App's <strong className="purple">Portfolio </strong>
            </h1>
            <p style={{ color: "white" }}>Welcome to our Portfolio</p>
          </div>
          <div>
            <p className="text-white">Filter by:</p>
            {/* <div className="d-md-flex"> */}
            <div className="justify-content-between">
              <DropdownButton
                id="dropdown-basic-button"
                title="Category"
                className="md-3 mb-sm-3 mb-md-0"
                size="sm"
              >
                {[
                  { tag: "aem", desc: "AEM" },
                  { tag: "ecommerce", desc: "E-commerce" },
                  { tag: "fintech", desc: "Fintech" },
                  { tag: "portfolio", desc: "Portfolio" },
                  { tag: "marketplace", desc: "Marketplace" },
                  { tag: "mobile-apps", desc: "Mobile Apps" },
                  { tag: "web-3", desc: "Web 3.0" },
                  { tag: "blockchain", desc: "Blockchain" },
                ].map(({ tag, desc }, index) => (
                  <Dropdown.Item
                    key={index}
                    onClick={() => handleFilterByCategory(tag)}
                  >
                    {desc}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
              <DropdownButton
                id="dropdown-basic-button"
                title="Languages"
                className="mt-md-3 mb-sm-3 mb-md-0"
                size="sm"
              >
                {[
                  "python",
                  "java",
                  "javascript",
                  "php",
                  "solidity",
                  "plutus",
                  "dart",
                  "kotlin",
                  "swift"

                ].map((item, index) => (
                  <Dropdown.Item
                    key={index}
                    onClick={() => handleFilterByLanguage(item)}
                  >
                    {item}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
              <DropdownButton
                id="dropdown-basic-button"
                title="Libraries"
                className="mt-md-3 mb-sm-3 mb-md-0"
                size="sm"
              >
                {["react", "react-native", "laravel", "django", "ruby on rails"].map(
                  (item, index) => (
                    <Dropdown.Item
                      key={index}
                      onClick={() => handleFilterByLibrary(item)}
                    >
                      {item}
                    </Dropdown.Item>
                  )
                )}
              </DropdownButton>
            </div>
          </div>
        </div>

        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          {filteredProjects.length > 0 ? (
            filteredProjects.map(
              ({ id, title, imgPath, description, link }, index) => (
                <Col md={6} lg={4} className="project-card" key={index}>
                  <ProjectCard
                    imgPath={imgPath}
                    isBlog={false}
                    title={title}
                    description={description}
                    link={link}
                  />
                </Col>
              )
            )
          ) : (
            <div className="text-light py-5">
              <p>No projects are here at the moment</p>
            </div>
          )}
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
