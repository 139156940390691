import React from "react";
import { MdOutlineWorkOutline } from "react-icons/md";
import { FaUserTie, FaCode } from "react-icons/fa";
import CountUp from "react-countup";
import { projectsData } from "../../utils/projectsData";
import VisibilitySensor from "react-visibility-sensor";
function Counter() {
  return (
    <div class="counters">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-sm-12 ">
            <div class="counter-box item-1">
              <FaUserTie />
              <CountUp start={0} end={13} duration={2} redraw={true}>
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <h1 class="counter Starting">
                      <span ref={countUpRef} />
                    </h1>
                  </VisibilitySensor>
                )}
              </CountUp>
              <p> Kaizen Family Members</p>
            </div>
          </div>
          <div class="col-md-4 col-sm-12">
            <div class="counter-box item-2">
              <MdOutlineWorkOutline />
              <CountUp
                start={0}
                end={projectsData.length}
                duration={2}
                redraw={true}
              >
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <h1 class="counter Starting">
                      <span ref={countUpRef} />
                    </h1>
                  </VisibilitySensor>
                )}
              </CountUp>
              <p>Projects Delivered</p>
            </div>
          </div>
          <div class="col-md-4 col-sm-12">
            <div class="counter-box item-3">
              <FaCode />
              <CountUp start={0} end={(projectsData.length) * 1525.547} duration={2} redraw={true}>
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <h1 class="counter Starting">
                      <span ref={countUpRef} />
                    </h1>
                  </VisibilitySensor>
                )}
              </CountUp>
              <p>Estimated Lines Of Code Typed</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Counter;
