import React from "react";
import image from "../Assets/logo-1.png";
import { FiMapPin } from "react-icons/fi";
import { GiAlarmClock } from "react-icons/gi";
import { GrMoney } from "react-icons/gr";
import { IoChevronForwardOutline } from "react-icons/io5";
import { Col } from "react-bootstrap";
function CareerItem({ job }) {
  const {
    title,
    location,
    nature,
    minSalary,
    maxSalary,
    minExp,
    maxExp,
    notes,
  } = job;
  return (
    <Col md={4} className="mt-4 pt-2">
      <div
        className="job-box bg-white overflow-hidden border rounded position-relative overflow-hidden"
        style={{ height: "100%" }}
      >
        <div className="p-4">
          <div className="row align-items-center">
            <div className="col-12 pl-0 ml-0">
              <div className="col-12 pl-0 ml-0">
                <img src={image} alt="" className="" />
              </div>
            </div>
            <div className="col-12 pl-2 ml-0">
              <div>
                <h3 className="f-18">{title}</h3>
              </div>
            </div>
            <div className="col-12 pl-2 ml-0">
              <div>
                <p className="text-muted mb-0">
                  <FiMapPin />
                  {location}
                </p>
              </div>
            </div>
            <div className="col-12 pl-2 ml-0">
              <div>
                <p className="text-muted mb-0 mo-mb-2">
                  <GrMoney />
                  <span className="text-primary">$</span>
                  {minSalary}-{""}
                  {maxSalary}
                  /h
                </p>
              </div>
            </div>
            <div className="col-12 pl-2 ml-0">
              <div>
                <p className="text-muted mb-0">
                  <GiAlarmClock />
                  {nature}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="p-3 bg-light">
          <div className="row">
            <div className="col-12 pl-3 ml-0">
              <div>
                <p className="text-muted mb-0 mo-mb-2">
                  <span className="text-dark">Experience :</span> {minExp} -{" "}
                  {maxExp} years
                </p>
              </div>
            </div>
            <div className="col-12 pl-3 ml-0">
              <div>
                <p className="text-muted mb-0 mo-mb-2">
                  <span className="text-dark">Notes :</span> {notes}{" "}
                </p>
              </div>
            </div>
            <div className="col-12 pl-3">
              <div>
                <a
                  href="https://forms.gle/j5gLxKDHc4dnytT97"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-primary mt-4"
                >
                  Apply Now <IoChevronForwardOutline />{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
}

export default CareerItem;
